// src/pages/MissingPetsPage.js
import React from 'react';
import MissingPets from '../components/MissingPets';

const MissingPetsPage = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white shadow">
        <div className="max-w-screen-xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          {/* You can add a title or breadcrumb here if needed */}
        </div>
      </header>

      <main>
        <MissingPets />
      </main>
    </div>
  );
};

export default MissingPetsPage;
