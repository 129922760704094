// src/components/FaqItem.js
import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FaqItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <div className="border-b border-gray-300 py-4">
      <button
        onClick={onClick}
        className="flex justify-between items-center w-full text-left focus:outline-none"
      >
        <span className="font-bold text-navy">{question}</span>
        <span className="ml-2 text-gray-700">{isOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
      </button>
      {isOpen && (
        <div className="mt-2 text-gray-700">
          {answer}
        </div>
      )}
    </div>
  );
};

export default FaqItem;
