import React from 'react';
import dogScanImage from '../assets/dogimg/dogscan1.png';

const heroes = [
  { name: 'John Doe', city: 'New York', image: dogScanImage },
  { name: 'Jane Smith', city: 'Los Angeles', image: dogScanImage },
  { name: 'Alice Johnson', city: 'Chicago', image: dogScanImage },
  { name: 'Bob Brown', city: 'Houston', image: dogScanImage },
  { name: 'John Doe', city: 'New York', image: dogScanImage },
  { name: 'Jane Smith', city: 'Los Angeles', image: dogScanImage },
  { name: 'Alice Johnson', city: 'Chicago', image: dogScanImage },
  { name: 'Bob Brown', city: 'Houston', image: dogScanImage },
  { name: 'Bob Brown', city: 'Houston', image: dogScanImage },
  { name: 'John Doe', city: 'New York', image: dogScanImage },
  { name: 'Jane Smith', city: 'Los Angeles', image: dogScanImage },
  { name: 'Alice Johnson', city: 'Chicago', image: dogScanImage },
  { name: 'Bob Brown', city: 'Houston', image: dogScanImage },
  { name: 'Bob Brown', city: 'Houston', image: dogScanImage },
  { name: 'John Doe', city: 'New York', image: dogScanImage },
  { name: 'Jane Smith', city: 'Los Angeles', image: dogScanImage },
  { name: 'Alice Johnson', city: 'Chicago', image: dogScanImage },
  { name: 'Bob Brown', city: 'Houston', image: dogScanImage },
];

const OurHero = () => {
  return (
    <div className="flex flex-col items-center w-full mt-20 mb-10 px-4 md:px-8 mx-auto max-w-screen-2xl">
      <h2 className="text-3xl font-bold text-center mb-4">
        Our  <span className="text-yellow-500">Heroes</span>
      </h2>
      <p className="text-sm sm:text-base text-gray-700 mb-8 px-4 sm:px-0 max-w-2xl mx-auto">
        100+ Pet reunited with their families!
      </p>
      <div className="flex flex-col justify-center items-center w-full">
        <div data-aos="zoom-in-right" className="w-full">
          <div className="flex justify-center">
            {/* Add pb-4 to provide space below the items, so the scrollbar doesn't overlap */}
            <div className="overflow-x-auto w-full pb-4">
              <div className="flex space-x-3 px-2">
                {heroes.map((hero, index) => (
                  <div key={index} className="flex-shrink-0 flex flex-col items-center m-1">
                    <div className="bg-white rounded-full shadow-md w-24 h-24 p-1 flex items-center justify-center">
                      <img
                        src={hero.image}
                        alt={`${hero.name}`}
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>
                    <p className="mt-1 text-xs font-semibold text-gray-800 text-center">
                      {hero.name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* Additional optional content can go here */}
      </div>
    </div>
  );
};

export default OurHero;
