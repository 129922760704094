



// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <nav className="w-full bg-cream text-gray-700">
      <div className="max-w-screen-xl mx-auto px-8 flex items-center justify-between py-4">
        {/* Left Section - Logo */}
        <div className="flex items-center">
          <Link to="/">
            {/* <img src={logoImage} alt="Logo" className="h-10 w-auto" /> */}
          </Link>
        </div>

        {/* Center Section - Navigation Links */}
        <div className="hidden md:flex space-x-6">
          <Link
            to="/"
            className="px-3 py-2 text-md font-medium hover:text-gray-900 focus:outline-none focus:shadow-outline"
          >
            Home
          </Link>
         
          <Link
            to="/blog"
            className="px-3 py-2 text-md font-medium hover:text-gray-900 focus:outline-none focus:shadow-outline"
          >
            Blog
          </Link>

          <Link
              to="/missing-pet"
            className="px-3 py-2 text-md font-medium hover:text-gray-900 focus:outline-none focus:shadow-outline"
              onClick={() => setOpen(false)}
            >
              Missing Pet
            </Link>
            <Link
              to="/about-us"
            className="px-3 py-2 text-md font-medium hover:text-gray-900 focus:outline-none focus:shadow-outline"
              onClick={() => setOpen(false)}
            >
              About Us
            </Link>

          <Link
            to="/contact-us"
            className="px-3 py-2 text-md font-medium hover:text-gray-900 focus:outline-none focus:shadow-outline"
          >
            Contact Us
          </Link>
        </div>

        {/* Right Section - Download Button */}
        <div className="hidden md:flex items-center space-x-4">
          <Link
            to="/download"
            className="px-6 py-2 text-lg font-medium text-white bg-yellow-500 rounded-full focus:outline-none focus:shadow-outline"
          >
            Download
          </Link>
        </div>

        {/* Mobile menu button */}
        <div className="md:hidden">
          <button
            onClick={() => setOpen(!open)}
            className="text-gray-800 focus:outline-none focus:shadow-outline"
          >
            {/* Icon */}
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              {open ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {open && (
        <div className="md:hidden bg-cream">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {/* Navigation Links */}
            <Link
              to="/"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-100"
              onClick={() => setOpen(false)}
            >
              Home
            </Link>
           
            <Link
              to="/blog"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-100"
              onClick={() => setOpen(false)}
            >
              Blog
            </Link>

            <Link
              to="/missing-pet"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-100"
              onClick={() => setOpen(false)}
            >
              Missing Pet
            </Link>

            <Link
              to="/contact-us"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-100"
              onClick={() => setOpen(false)}
            >
              Contact Us
            </Link>

            {/* Download Button */}
            <div className="mt-4 flex flex-col space-y-2">
              <Link
                to="/download"
                className="block text-center px-3 py-2 rounded-full text-base font-medium text-white bg-yellow-500 focus:outline-none focus:shadow-outline"
                onClick={() => setOpen(false)}
              >
                Download
              </Link>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;


