// src/components/CardsSection.js
import React from 'react';
import Card from './Card';

// Import your SVG icons
// import myIcon1 from '../assets/images/company/recipts10.svg';
// import myIcon2 from '../assets/images/company/Groceries.svg';
// import myIcon3 from '../assets/images/company/Recipes.svg';
import myIcon1 from '../assets/dogimg/nose.png';
import myIcon2 from '../assets/dogimg/mouth.png';
import myIcon3 from '../assets/dogimg/human.png';

const CardsSection = () => {
  return (
    <div className="flex flex-wrap justify-center gap-20 mt-10 mb-20">
      <Card
        icon={<img src={myIcon1} alt="Feature 1" className="w-6 h-6" />}
        bgColor="#0095FF"
        title="Scan Pet"
        description="Capture your pet's unique features using your smartphone's camera. Our AI analyzes these to create a precise biometric profile"
      />
      <Card
        icon={<img src={myIcon2} alt="Feature 2" className="w-6 h-6" />}
        bgColor="#0095FF"
        title="Pet Details"
        description="Enter your pet's name, breed, sex, and birth date to complete their profile."
      />
      <Card
        icon={<img src={myIcon3} alt="Feature 3" className="w-6 h-6" />}
        bgColor="#0095FF"
        title="Contact Info"
        description="Provide your contact information to link with your pet's profile, ensuring prompt communication if your pet is found"
      />
    </div>
  );
};

export default CardsSection;

