// src/pages/AboutUsPage.js
import React from 'react';
import { FaLinkedin, FaInstagram, FaFacebook, FaTiktok } from 'react-icons/fa';
import FaqSection from '../components/FaqSection';


const AboutUsPage = () => {
  return (
    <div className="bg-white text-navy">
      {/* Page Container */}
      <div className="max-w-screen-xl mx-auto px-4 md:px-8 py-12">

        {/* Founder Section */}
        <section className="mb-12">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold leading-tight text-navy mb-6">
            About Us
          </h1>
          <div className="bg-cream p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-2">Raman Rajoo</h2>
            <h3 className="text-xl font-semibold mb-4">Founder</h3>
            <p className="text-base md:text-lg leading-relaxed">
              Raman's unwavering commitment revolves around elevating the quality of life for pets
              and their caretakers through the provision of a steadfast, trustworthy, and highly
              efficient platform designed to facilitate the prompt identification and reunification of
              lost pets with their respective families. His self-directed learning and steadfast
              determination are fueled by a deep-seated love for animals, complemented by a resolute
              entrepreneurial vision.
            </p>
          </div>
        </section>

        {/* Data for Justice Project Section */}
        <section className="mb-12">
          <h2 className="text-2xl sm:text-3xl font-bold text-navy mb-4">Welcome to Data for Justice Project</h2>
          <div className="p-6 bg-gray-50 rounded-lg shadow-md leading-relaxed space-y-4">
            <p>
              Dog fighting, a reprehensible underground crime, demands urgent legal and political
              attention. Once condoned by aristocracy and embraced by medieval gentry, this blood
              sport is now outlawed in S.A. Despite the ban, it persists in urban and rural areas,
              alarming South Africa. Law enforcement, historically dismissing it as a mere animal
              welfare concern, has failed to grasp its full extent. Communities scarred by dogfighting
              pay the price for this indifference, as children grow up normalized to violence,
              becoming desensitized and prone to criminalization. Dog fighters engage in a range of
              criminal activities, necessitating a comprehensive approach to combat this pervasive
              issue.
            </p>
            <p>
              In recent years, enlightened authorities acknowledge the interconnected nature of
              dogfighting with organized crime, drug distribution, and community violence. Denying its
              prevalence is increasingly untenable in the face of compelling legal and empirical
              evidence. It's time to confront this dark reality and take decisive action.
            </p>
            <a
              href="https://animallaw.info"
              className="text-blue-600 underline hover:text-blue-800"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://animallaw.info
            </a>
          </div>

          {/* Take Action Section */}
          <div className="mt-8 p-6 bg-gray-50 rounded-lg shadow-md space-y-4">
            <h3 className="text-xl font-bold">Take Action!</h3>
            <p>
              Harnessing data is crucial in combating criminal activities, and the brutal practice of
              dog fighting is no exception. Big data yields valuable insights into incident
              geolocation, socioeconomic context, and victim animal breeds. Establishing a
              comprehensive database can be a game-changer in addressing this tragedy.
            </p>
            <p>
              Currently scarce due to illegality and criminal ties, data is closely guarded by the
              SPCA's special investigations unit. Coba Tech aims to launch the "Data for Justice"
              employing its PreCog Machine Learning API to analyze and deliver reliable probability
              data on dog fighting hotspots. Combined with real-time app reports, this data will be
              submitted securely to the NSPCA's SIU.
            </p>
            <a
              href="#register"
              className="inline-block bg-yellow-500 text-white py-2 px-4 rounded hover:bg-blue-800 transition-colors duration-200"
            >
              Register to participate
            </a>
          </div>
        </section>

        {/* Investment Opportunity Section */}
        <section className="mb-12">
          <h2 className="text-2xl sm:text-3xl font-bold text-navy mb-4">INVESTMENT OPPORTUNITY</h2>
          <div className="p-6 bg-white rounded-lg shadow-md space-y-4 leading-relaxed">
            <p>
              Discover the potential of IntelliPaw, the innovative AI-driven solution at the forefront
              of pet safety. Our unique technology harnesses the power of machine learning to
              accurately identify and protect our cherished companions with just a scan of their
              snout print.
            </p>
            <p>
              As we chart a course for growth and industry leadership, we invite forward-thinking
              investors to explore the remarkable opportunities IntelliPaw offers. Visit our website
              to see the impactful difference your investment can make in the booming pet care market
              and be part of a visionary journey to redefine pet protection.
            </p>
            <a
              href="#pitch"
              className="inline-block bg-yellow-500 text-white py-2 px-4 rounded hover:bg-green-700 transition-colors duration-200"
            >
              View the Investment Pitch
            </a>
          </div>
        </section>

        {/* FAQ Section */}
        {/* <section className="mb-12"> */}
          
          <FaqSection />

        {/* </section> */}

      </div>
    </div>
  );
};

export default AboutUsPage;
