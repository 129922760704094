import React from 'react';

const AllInOneSoftware = () => {
  return (
    <div data-aos="flip-up" className="max-w-xl mx-auto text-center mt-24">
      <h1 className="font-bold text-darken my-3 text-2xl">
        All-In-One <span className="text-yellow-500">Pet solution.</span>
      </h1>
      <p className="leading-relaxed text-gray-500">
        
      </p>
    </div>
  );
};

export default AllInOneSoftware;
