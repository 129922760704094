// src/components/PhysicalClassroomSection.js
import React from 'react';
import rightSideImage from '../assets/dogimg/dogscan1.png';

const OurMission = () => {
  return (
    <section className="mt-20 px-6 sm:px-12 lg:px-24 bg-gray-100">
      <div className="max-w-screen-xl mx-auto flex flex-col-reverse lg:flex-row items-start lg:items-center lg:justify-between py-12">
        {/* Left Side - Text Content */}
        <div data-aos="fade-right" className="w-full lg:w-2/3 relative lg:pr-12">
          {/* Decorative Circle */}
          <div className="bg-cream rounded-full absolute w-12 h-12 z-0 -left-4 -top-4 animate-pulse"></div>

          {/* "Our Mission" Heading */}
          <h1 className="font-semibold text-3xl lg:text-4xl relative z-50 text-darken mb-6">
            Our Mission
          </h1>
          {/* Mission Description */}
          <p className="text-gray-700 leading-relaxed mb-6">
          At Intellipaw, our mission is to create a world where every pet is uniquely identifiable, ensuring that lost pets are swiftly reunited with their owners. We strive to enhance pet safety and well-being through innovative, non-invasive biometric identification solutions  </p>

          {/* "How EatNice Works" Heading */}
          <h2 className="font-semibold text-2xl lg:text-3xl relative z-50 text-darken mb-4">
            How <span className="text-yellow-500">Intellipaw</span> Works?    </h2>
          {/* How It Works Description */}
          <p className="text-gray-700 leading-relaxed">
          intellipaw utilizes your smartphone's camera to capture your pet's unique biometric features, such as a dog's nose print or a cat's facial contours. By analyzing these characteristics, the app creates a precise biometric profile linked to your contact information. This system ensures that if your pet is ever lost, finders can easily identify them and facilitate a swift reunion          </p>
        </div>

        {/* Right Side - Image */}
        <div
          data-aos="fade-left"
          className="w-full lg:w-1/3 relative mb-10 lg:mb-0 flex justify-center lg:pl-12"
        >
          <img
            className="rounded-xl z-50 relative max-w-md w-full"
            src={rightSideImage}
            alt="EatNice in Action"
          />
          {/* Add other floating elements if needed */}
        </div>
      </div>
    </section>
  );
};

export default OurMission;
