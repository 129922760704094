// src/components/Membership.js
import React from 'react';

const membershipPlans = [
  {
    plan: 'Premium',
    duration: 'Lifetime',
    amount: 'R1800',
    type: 'once off',
    features: [
      'Unlimited Access',
      'Priority Support',
      'Exclusive Content',
      'Free Updates',
    ],
  },
  {
    plan: 'Basic',
    duration: 'Lifetime',
    amount: 'R1200',
    type: 'once off',
    features: [
      'Limited Access',
      'Standard Support',
      'Basic Content',
    ],
  },
  {
    plan: 'Premium',
    duration: 'Monthly',
    amount: 'R69',
    type: 'monthly',
    features: [
      'Unlimited Access',
      'Priority Support',
      'Exclusive Content',
      'Free Updates',
    ],
  },
  {
    plan: 'Basic',
    duration: 'Monthly',
    amount: 'R49',
    type: 'monthly',
    features: [
      'Limited Access',
      'Standard Support',
      'Basic Content',
    ],
  },
];

const Membership = () => {
  return (
    <div className="flex flex-col items-center w-full mt-8 mb-16 px-4 md:px-16 mx-auto max-w-screen-2xl">
      <h1 className="text-2xl sm:text-3xl  font-bold text-center mb-4">
        <span className="text-yellow-500">Membership</span> Plans
      </h1>
      <div className="w-full">
        <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
          {membershipPlans.map((plan, index) => (
            <div
              key={index}
              className={`bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 ${
                plan.plan === 'Premium'
              }`}
            >
              <div className="p-6">
                <h3 className="text-2xl font-semibold mb-2">{plan.plan}</h3>
                <p className="text-gray-600 mb-1">
                  <span className="font-medium">Duration:</span> {plan.duration}
                </p>
                <p className="text-yellow-500 text-xl font-bold mb-4">
                  {plan.amount} <span className="text-gray-600">{plan.type}</span>
                </p>
                <ul className="text-gray-600 mb-4">
                  {plan.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center mb-2">
                      <svg
                        className="w-5 h-5 text-yellow-500 mr-2 flex-shrink-0"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414L8.414 15 3.707 10.293a1 1 0 00-1.414 1.414l5 5a1 1 0 001.414 0l9-9a1 1 0 00-1.414-1.414L8.414 13.586 16.707 5.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>
                {/* <button className="w-full bg-yellow-500 text-white py-2 px-4 rounded-md hover:bg-yellow-600 transition-colors duration-300">
                  Select Plan
                </button> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Membership;
