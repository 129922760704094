// 

import React from 'react';
import HeroSection from '../components/HeroSection';
import TrustedBy from '../components/TrustedBy';
import AllInOneSoftware from '../components/AllInOneSoftware';
import CardsSection from '../components/CardsSection';
import JoinCommunity from '../components/JoinCommunity';
import OurMission from '../components/OurMissionSection';
import WorkFlowSection from '../components/WorkFlowSection';
import OurHero from '../components/OurHero';
import LatestNews from '../components/LatestNews';
import MissingPets from '../components/MissingPets';
import Membership from '../components/Membership';



const HomePage = () => {
  return (
    <div>
      <HeroSection />
      <MissingPets />
      <OurMission/>
      <Membership />
      <WorkFlowSection /> 
      <AllInOneSoftware />
      <CardsSection />
      {/* <TrustedBy /> */}
           
       
      <LatestNews />
      <OurHero/>
      


      <JoinCommunity />
    </div>
  );
};

export default HomePage;
