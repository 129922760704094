// src/components/Footer.js
// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
// import logoImage from '../assets/images/whitelogo.png';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="mt-32 bg-black text-white">
      <div className="max-w-screen-xl mx-auto px-6 sm:px-12 lg:px-24 py-12">
        <div className="flex flex-col md:flex-row justify-between items-center">
          {/* Logo Section */}
          <div className="mb-6 md:mb-0">
            {/* <img src={logoImage} alt="Logo" className="w-32 h-auto" /> */}
          </div>
          {/* Links Section */}
          <div className="flex space-x-6 mb-6 md:mb-0">
            <Link to="/blog" className="hover:underline">
              Blog
            </Link>
            <Link to="/privacy-policy" className="hover:underline">
              Privacy Policy
            </Link>
            <Link to="/terms-of-service" className="hover:underline">
              Terms of Service
            </Link>
          </div>
          {/* Social Media Icons */}
          <div className="flex space-x-4">
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <FaFacebookF className="w-6 h-6 hover:text-gray-400" />
            </a>
            <a
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <FaTwitter className="w-6 h-6 hover:text-gray-400" />
            </a>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <FaInstagram className="w-6 h-6 hover:text-gray-400" />
            </a>
          </div>
        </div>
        {/* Footer Bottom Text */}
        <div className="mt-8 text-center text-sm text-gray-500">
          &copy; {new Date().getFullYear()} Intellipaw. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;


